import { Card, CardContent, Typography } from "@mui/material";

const Contact = () => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Contact Us</Typography>
        <Typography variant="body2">Email: info@ccoredcameroon.org</Typography>
        <Typography variant="body2">Tel: 677777777</Typography>
      </CardContent>
    </Card>
  );
};

const Goal = () => {
  return (
    <div className="overview">
      <h2 className="heading2">Goal</h2>
      <p className="paragraph">
        Aims to empower individuals and communities to effectively address their
        development issues by promoting diverse poverty reduction initiatives
        and sustainable social transformations through community participation
        and effective stakeholder cooperation to trigger social and economic
        development.
      </p>
    </div>
  );
};

const Vision = () => {
  return (
    <div className="overview">
      <h2 className="heading2">Our Vision</h2>
      <p className="paragraph">
        Empowered, safe, responsible and educated individuals and communities
        with ability to effectively identify and address their social and
        development issues.
      </p>
    </div>
  );
};

const Mission = () => {
  return (
    <div className="overview">
      <h2 className="heading2">Our Mission</h2>
      <p className="paragraph">
        To nurture individuals and communities in the conceptualization, design,
        execution and evaluation of social and development initiatives and to
        provide a platform that engages all stakeholders in the development
        processes.
      </p>
    </div>
  );
};

const Objectives = () => {
  return (
    <div className="overview">
      <h2 className="heading2">Objectives</h2>
      <p className="paragraph">
        Aims at giving a thrust on sustainable livelihoods, participatory
        reconstruction, management and community mobilization as well as engage
        all sectors of society (business, government and civil society) - in the
        community development to support community-led (community-based)
        initiatives. To demonstrate the power of the community to create
        sustainable change, using CCORED active citizen framework, on a nation
        scale through advocacy, action and evaluation. To ensure education for
        children, livelihood for the youth, healthcare in rural communities,
        women empowerment and sensitization of the underprivileged to effect
        change. Investing in women and families by creating a social network to
        enable them build livelihoods, address inequality and create
        opportunities for self-sufficiency.
      </p>
    </div>
  );
};

const AboutUs = () => {
  return (
    <div className="overview">
      <h2 className="heading2">CCORED Cameroon</h2>
      <p className="paragraph">
        The Centre for Community Reconstruction and Development (CCORED) is a
        non-profit and non-governmental organization (NGO) that was founded in
        2023 by a group of illustrious sons and daughters of Cameroon with the
        primary goal to provide community-centered development interventions and
        reconstruction geared towards sustainable community transformation
        (social and economic). The community development and resource initiative
        was named: Centre for Community Reconstruction and Development (CCORED).
        The Centre for Community Reconstruction and Development (CCORED)
        believes that if individuals and communities are duly empowered, they
        can spearhead their own community reconstruction and development.
      </p>
    </div>
  );
};

const Home = () => {
  return (
    <>
      <AboutUs />
    </>
  );
};

const News = () => {
  return <>News and Events</>;
};

const Projects = () => {
  return <>Current Projects</>;
};

export {
  Home,
  News,
  Projects,
  Goal,
  Contact,
  Mission,
  AboutUs,
  Objectives,
  Vision,
};
