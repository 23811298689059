import {
  Card,
  CardContent,
  CardHeader,
  Link,
  List,
  ListItem,
  Typography,
} from "@mui/material";

const quicklinks = [
  { link: "/", text: "Home" },
  { link: "/projects", text: "Projects" },
  { link: "/contact", text: "Contact Us" },
  { link: "/about", text: "About Us" },
];

const QuickLinks = () => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Quick links</Typography>
        <List>
          {quicklinks.map((item) => (
            <ListItem>
              <Link underline="none" href={item.link}>
                {item.text}
              </Link>
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};
export default QuickLinks;
