import { Typography } from "@mui/material";

const Logo = () => {
  return (
    <Typography variant="h6" sx={{ my: 2 }}>
      CCORED
    </Typography>
  );
};
export default Logo;
