import GoogleIcon from "@mui/icons-material/Google";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div>
          <p>&copy; 2024 CCORED Cameroon. All rights reserved.</p>
        </div>
        <div className="icons">
          <span>
            <GoogleIcon />
          </span>
          <span>
            <FacebookIcon />
          </span>
          <span>
            <TwitterIcon />
          </span>
          <span>
            <InstagramIcon />
          </span>
        </div>
      </footer>
    </>
  );
};
export default Footer;
