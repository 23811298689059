import { Container, Grid, Stack } from "@mui/material";
import Partners from "./partners";
import NewsLetter from "./newsletter";
import QuickLinks from "./quicklinks";

const BottomPane = () => {
  return (
    <Grid container direction={{ xs: "column", sm: "row" }} spacing="5">
      <Grid columns={2}>
        <Partners />
      </Grid>
      <Grid columns={1}>
        <QuickLinks />
      </Grid>
      <Grid columns={2}>
        <NewsLetter />
      </Grid>
    </Grid>
  );
};
export default BottomPane;
