import "./App.css";
import { useState, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import MainPage from "./mainpage";
import Footer from "./components/footer";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Layout from "./components/links";
import { AboutUs, Contact, Home, News, Projects } from "./pages";
import AppRouter from "./Router";

const App = () => {
  const [screenSize, setScreenSize] = useState(getCurrentDimension());
  const [menuOpen, setMenuOpen] = useState(false);
  const handleMenuOpen = () => {
    setMenuOpen((menuOpen) => !menuOpen);
  };

  {
    /* 
To track the window dimensions:
Window Dimensions Tracking Function : getCurrentDimension() retrieves the current window width and height. 
*/
  }
  function getCurrentDimension() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);

    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenSize]);
  return (
    <>
      <AppRouter />
    </>
  );
};

export default App;
