import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/layout";
import { Home, News, Projects, AboutUs } from "./pages";
import Contact from "./components/contact";

const AppRouter = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="#news" element={<News />} />
            <Route path="#contact" element={<Contact />} />
            <Route path="#projects" element={<Projects />} />
            <Route path="#about" element={<AboutUs />} />
          </Route>
        </Routes>
      </Router>
    </>
  );
};
export default AppRouter;
