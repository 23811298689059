import { Box, Container } from "@mui/material";
import Footer from "./footer";
import DrawerAppBar from "./drawerAppBar";
import BottomPane from "./bottomPane";
import { Outlet } from "react-router-dom";

const AppLayout = () => {
  return (
    <Box sx={{ padding: "0px 0px 30px" }}>
      <DrawerAppBar />
      <Box sx={{ maxWidth: "md", marginX: "auto" }}>
        <Outlet />
        <BottomPane />
      </Box>
      <Footer />
    </Box>
  );
};
export default AppLayout;
