import {
  Button,
  Card,
  CardHeader,
  CardContent,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";

const NewsLetter = () => {
  const [email, setEmail] = useState("Email ... ");
  const handleSubscription = () => {
    setEmail((email) => "");
  };
  return (
    <Card>
      <CardContent>
        <Typography variant="h5" className="heading2">
          Newsletter Subscription
        </Typography>
        <Typography variant="body1">
          We will keep you up to date with News and Events
        </Typography>
        <TextField size="small" value={email} />
        <Button variant="contained" onClick={handleSubscription}>
          Subscribe
        </Button>
      </CardContent>
    </Card>
  );
};
export default NewsLetter;
