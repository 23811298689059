import { Link } from "react-router-dom";

const links = [
  { ref: "home", text: "Home" },
  { ref: "news", text: "News" },
  { ref: "projects", text: "Projects" },
  { ref: "contact", text: "Contact Us" },
  { ref: "about", text: "About Us" },
];

const Navigation = () => {
  return (
    <>
      <a href="/">Home</a>
      <a href="/#news">News</a>
      <a href="/#projects">Projects</a>
      <a href="/#contact">Contact Us</a>
      <a href="/#about">About Us</a>
    </>
  );
};
export { links };
export default Navigation;
