import {
  Card,
  CardContent,
  CardHeader,
  List,
  ListItem,
  Typography,
} from "@mui/material";

const Partners = () => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h5">Our Partners</Typography>
        <List>
          <ListItem>
            <Typography variant="body2">Nature Cameroon</Typography>
          </ListItem>

          <ListItem>
            <Typography variant="body2">Lifafa Museum</Typography>
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};
export default Partners;
