import { Card } from "@mui/material";

const Contact = () => {
  return (
    <div>
      <h4>Contact Us</h4>
      <Card>
        <p>Great Soppo Bonduma </p>
        <p>South West Region </p>
        <p>Cameroon </p>
        <p>Tel: +237 672080063</p>
        <p>Email: info@ccoredcameroon.org</p>
      </Card>
    </div>
  );
};
export { Contact };
export default Contact;
